import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ExpandablePanel } from "@entur/expand";
import { Link, ListItem, NumberedList } from '@entur/typography';
import './guides.scss';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "recommendations-guide"
    }}>{`Recommendations Guide`}</h1>
    <h2 {...{
      "id": "table-of-contents"
    }}>{`Table of Contents`}</h2>
    <NumberedList className="offers-guides-toc" mdxType="NumberedList">
    <ListItem mdxType="ListItem">
        <Link href="#introduction" mdxType="Link">Introduction</Link>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#grouping-configuration" mdxType="Link">Grouping configuration</Link>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#algorithm-configuration" mdxType="Link">Algorithm configuration</Link>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#recommendation-response" mdxType="Link">Recommendation response</Link>
    </ListItem>
    </NumberedList>
    <br />
    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p>{`Each search-endpoint that takes in any kind of information about geography, has the option to supply recommendations.
Recommendations groups offers based on certain attributes, matching the specified `}<inlineCode parentName="p">{`recommendationConfig`}</inlineCode>{` object of the request.
This can be a particularly useful feature if you are looking for a specific type of offer, but are not sure which one to choose.
It also includes for which travellers and what part of the journey/area the recommendation is valid for.`}</p>
    <h3 {...{
      "id": "grouping-configuration"
    }}>{`Grouping configuration`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p>{`Each recommendation is categorized by different attributes. The client may provide which attributes they would like to group the recommendations by. Note that each offer may be recommended in more than one combination of grouping attributes.
Attributes for recommendations are divided into flexibility, duration type, fare class and facility set, and are specified in the `}<inlineCode parentName="p">{`categorySpec`}</inlineCode>{` part of the `}<inlineCode parentName="p">{`recommendationConfig`}</inlineCode>{`.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Flexibility`}</inlineCode>{` - how flexible the offer is in terms of exchangeability and refundability. This is requested by the `}<inlineCode parentName="li">{`typesOfRecommendation`}</inlineCode>{` array, where the last option `}<inlineCode parentName="li">{`CHEAPEST`}</inlineCode>{` ignores the flexibility but selects the cheapest offer that fits the other requested attributes.`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`NON_FLEXIBLE`}</inlineCode>{` no refund or exchange options`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`SEMI_FLEXIBLE`}</inlineCode>{` can refund or exchange but not both`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`FLEXIBLE`}</inlineCode>{` both refundable and exchangeable`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`DurationType`}</inlineCode>{` - the duration of the period ticket the offers describes. The most typical values are:`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`SINGLE_TRIP`}</inlineCode>{` valid for one trip`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`DAY_PASS`}</inlineCode>{` valid for a day`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`WEEKLY_PASS`}</inlineCode>{` valid for a week`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`MONTHLY_PASS`}</inlineCode>{` valid for a month`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`SEASON_TICKET`}</inlineCode>{` valid for a season or a year`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`CARNET`}</inlineCode>{` valid for a number of trips`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`FareClass`}</inlineCode>{` - the class of the product offered. i.e. `}<inlineCode parentName="li">{`PREMIUM_CLASS`}</inlineCode>{` or `}<inlineCode parentName="li">{`STANDARD_CLASS`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`FacilitySet`}</inlineCode>{` - to request recommendation for offers with specific facilities that goes beyond a simple right of travel such as `}<inlineCode parentName="li">{`SEATING`}</inlineCode>{`, `}<inlineCode parentName="li">{`RECLINING_SEAT`}</inlineCode>{`, `}<inlineCode parentName="li">{`SLEEPER`}</inlineCode>{` or `}<inlineCode parentName="li">{`COUCHETTE`}</inlineCode></li>
    </ul>
    <p>{`In addition to specific attributes provided in `}<inlineCode parentName="p">{`categorySpec`}</inlineCode>{`, it is also possible to specify wildcard values for most of the attributes such as `}<inlineCode parentName="p">{`ANY`}</inlineCode>{` for `}<inlineCode parentName="p">{`fareClasses`}</inlineCode>{` or `}<inlineCode parentName="p">{`ANY_FACILITY_SET`}</inlineCode>{` for `}<inlineCode parentName="p">{`facilitySet`}</inlineCode>{`.
This allows for combining recommendations that point to offers that have a specific attribute, but also recommend the cheapest options when not taking the attribute into consideration.
Typically useful for grouping offers for e.g. sleeper and non-sleeper, but still recommending the cheapest option within each grouping.
If any of `}<inlineCode parentName="p">{`facilitySet`}</inlineCode>{`, `}<inlineCode parentName="p">{`fareClass`}</inlineCode>{` or `}<inlineCode parentName="p">{`durationType`}</inlineCode>{` is not specified, it will implicitly act as a wild card and the recommendation will include all offers that fit the other specified attributes.
`}<inlineCode parentName="p">{`typesOfRecommendation`}</inlineCode>{` always has to contain at least one value.`}</p>
    <ExpandablePanel title="Example Request Cheapest overall" mdxType="ExpandablePanel">
    <pre>{`{...,
    "recommendationConfig": {
        "categorySpec": {
            "typesOfRecommendation": [
                "CHEAPEST"
            ]
        }
    }
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Funtionally equivalent example request for cheapest overall" mdxType="ExpandablePanel">
    <pre>{`{...,
    "recommendationConfig": {
        "categorySpec": {
            "typesOfRecommendation": [
                "CHEAPEST"
            ],
            "durationTypes": [
                "ANY",
            ],
            "fareClasses": [
                "ANY"
            ],
            "facilitySet": [
                "ANY_FACILITY_SET"
            ]
        }
    }
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example Response with Cheapest overall" mdxType="ExpandablePanel">
    <pre>{`{...,
    "recommendations": [
        {
            "geographicalValidityCovered": ...,
            "typeOfRecommendation": "CHEAPEST",
            "offersToBuy": [...]
        }
    ]
}`}</pre>
    </ExpandablePanel>
    <h4 {...{
      "id": "detailed-examples"
    }}>{`Detailed examples`}</h4>
    <p>{`Below follows some detailed examples showing the result of applying a subset of the available grouping attributes.`}</p>
    <p>{`All examples below show examples of classification of the following example Offers`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Id`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Price`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Flexibility`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DurationType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`FacilitySet`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NonFlexibleSeating`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NON_FLEXIBLE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SINGLE_TRIP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SEATING`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NonFlexibleCouchette`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NON_FLEXIBLE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SINGLE_TRIP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`COUCHETTE`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SemiFlexibleSeating`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SEMI_FLEXIBLE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SINGLE_TRIP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SEATING`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SemiFlexibleSleeper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SEMI_FLEXIBLE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SINGLE_TRIP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SLEEPER`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`FlexibleCouchette`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FLEXIBLE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SINGLE_TRIP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`COUCHETTE`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`FlexibleSleeper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`60`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FLEXIBLE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SINGLE_TRIP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SLEEPER`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`WeekPass`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`70`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NON_FLEXIBLE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WEEKLY_PASS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`_`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`MonthPass`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`80`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NON_FLEXIBLE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MONTHLY_PASS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`_`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "cheapest-overall"
    }}>{`Cheapest overall`}</h5>
    <ul>
      <li parentName="ul">{`typeOfRecommendation: CHEAPEST`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Offer recommended`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`typeOfRecommendation`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NonFlexibleSeating`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CHEAPEST`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "cheapest-across-flexibility"
    }}>{`Cheapest across flexibility`}</h5>
    <ul>
      <li parentName="ul">{`typesOfRecommendation: CHEAPEST, NON_FLEXIBLE, SEMI_FLEXIBLE, FLEXIBLE`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Offer recommended`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`typeOfRecommendation`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NonFlexibleSeating`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CHEAPEST`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NonFlexibleSeating`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NON_FLEXIBLE`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SemiFlexibleSeating`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SEMI_FLEXIBLE`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`FlexibleCouchette`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FLEXIBLE`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "cheapest-across-facilitysets"
    }}>{`Cheapest across FacilitySets`}</h5>
    <ul>
      <li parentName="ul">{`typesOfRecommendation: CHEAPEST`}</li>
      <li parentName="ul">{`facilitySet: ANY_FACILITY_SET, SEATING, COUCHETTE, SLEEPER`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Offer recommended`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`typeOfRecommendation`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`FacilitySet`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NonFlexibleSeating`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CHEAPEST`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ANY_FACILITY_SET`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NonFlexibleSeating`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CHEAPEST`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SEATING`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NonFlexibleCouchette`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CHEAPEST`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`COUCHETTE`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SemiFlexibleSleeper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CHEAPEST`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SLEEPER`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "cheapest-across-facilitysets-and-flexibility"
    }}>{`Cheapest across FacilitySets and flexibility`}</h5>
    <ul>
      <li parentName="ul">{`typesOfRecommendation: CHEAPEST, NON_FLEXIBLE, SEMI_FLEXIBLE, FLEXIBLE`}</li>
      <li parentName="ul">{`facilitySet: ANY_FACILITY_SET, SEATING, COUCHETTE, SLEEPER`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Offer recommended`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`typeOfRecommendation`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`FacilitySet`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NonFlexibleSeating`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CHEAPEST`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ANY_FACILITY_SET`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NonFlexibleSeating`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CHEAPEST`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SEATING`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NonFlexibleCouchette`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CHEAPEST`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`COUCHETTE`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SemiFlexibleSleeper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CHEAPEST`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SLEEPER`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NonFlexibleSeating`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NON_FLEXIBLE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ANY_FACILITY_SET`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NonFlexibleSeating`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NON_FLEXIBLE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SEATING`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NonFlexibleCouchette`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NON_FLEXIBLE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`COUCHETTE`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SemiFlexibleSeating`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SEMI_FLEXIBLE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ANY_FACILITY_SET`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SemiFlexibleSeating`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SEMI_FLEXIBLE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SEATING`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SemiFlexibleSleeper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SEMI_FLEXIBLE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SLEEPER`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`FlexibleCouchette`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FLEXIBLE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ANY_FACILITY_SET`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`FlexibleCouchette`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FLEXIBLE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`COUCHETTE`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`FlexibleSleeper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FLEXIBLE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SLEEPER`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Note that the combinations of NON_FLEXIBLE + SLEEPER, SEMI_FLEXIBLE + COUCHETTE and FLEXIBLE + SEATING are not recommended due to no available offers in those combinations of attributes`}</p>
    <h5 {...{
      "id": "cheapest-across-durationtype"
    }}>{`Cheapest across durationType`}</h5>
    <ul>
      <li parentName="ul">{`typesOfRecommendation: CHEAPEST`}</li>
      <li parentName="ul">{`durationType: SINGLE_TRIP, WEEKLY_PASS, MONTHLY_PASS`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Offer recommended`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Flexibility`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DurationType`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NonFlexibleSeating`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CHEAPEST`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SINGLE_TRIP`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`WeekPass`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CHEAPEST`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WEEKLY_PASS`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`MonthPass`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CHEAPEST`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MONTHLY_PASS`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "algorithm-configuration"
    }}>{`Algorithm configuration`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p>{`For more control over how the recommendations are calculated, you can specify rules in the `}<inlineCode parentName="p">{`ruleSpec`}</inlineCode>{` part of the `}<inlineCode parentName="p">{`recommendationConfig`}</inlineCode>{`:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`journeyOrganizeAlgorithm`}</inlineCode>{` - how the recommendations are created for parts of a composite journey, where a leg is identified by a service journey id.
The available options are (with an example journey of 3 legs; SJ1, SJ2 and SJ3):`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`SUBSEQUENT_COMBINATIONS`}</inlineCode>{` - all possible subsequent combinations of legs, i.e. recommendations for SJ1, SJ2, SJ3, SJ1+SJ2, SJ2+SJ3 and SJ1+SJ2+SJ3`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`COMBINATIONS_FROM_OFFERS`}</inlineCode>{` - all unique journey combinations covered by offers in the response`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`FOR_EACH_AND_GROUPED_COMBINATIONS`}</inlineCode>{` - one for each individual part of the journey in addition to the entire trip, i.e. recommendations for SJ1, SJ2, SJ3 and SJ1+SJ2+SJ3`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`priceComparisonAlgorithm`}</inlineCode>{` - how the price of the offers are compared when picking offers to buy for a recommendation.
The default is `}<inlineCode parentName="li">{`TOTAL_PRICE`}</inlineCode>{` which compares the actual price for the offer. The other option is `}<inlineCode parentName="li">{`BEFORE_SDR`}</inlineCode>{` which compares the price of the offer before a discount to the product is applied.
`}<inlineCode parentName="li">{`BEFORE_SDR`}</inlineCode>{` can be useful if the traveller has an entitlement that gives them offers with greatly reduced prices, but the original price matter in terms of later taxation, or other considerations.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`onlyIncludeRecommendedOffers`}</inlineCode>{` - if set to `}<inlineCode parentName="li">{`true`}</inlineCode>{`, only offers that are recommended will be included in the response. The default is `}<inlineCode parentName="li">{`false`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`onlyIncludeRecommendationsWithOffersToBuy`}</inlineCode>{` - if set to `}<inlineCode parentName="li">{`true`}</inlineCode>{`, only recommendations which actually points to offers to buy will be included in the response.
Often it does not exist offers such that every criterion in the requested recommendation config can point to an offer to buy,
so this can be used to filter out recommendations that can be considered void. The default is `}<inlineCode parentName="li">{`true`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`mixinOffersWithHigherFlexibility`}</inlineCode>{` - if set to `}<inlineCode parentName="li">{`true`}</inlineCode>{`, offers with higher flexibility will be included in the response.
F.ex. if FLEXIBLE recommendations are requested and there exists a FLEXIBLE offer that is cheaper than a NON_FLEXIBLE offer,
with this parameter set to `}<inlineCode parentName="li">{`true`}</inlineCode>{` the FLEXIBLE offer will be recommended in the recommendation with category NON_FLEXIBLE. The default is `}<inlineCode parentName="li">{`true`}</inlineCode>{`.`}</li>
    </ul>
    <ExpandablePanel title="Example Request with Rule Spec" mdxType="ExpandablePanel">
    <pre>
    {`
         "recommendationConfig": {
             "categorySpec": {
               "typesOfRecommendation": [
                 "CHEAPEST"
               ]
             },
             "ruleSpec": {
               "journeyOrganizeAlgorithm": "COMBINATIONS_FROM_OFFERS",
               "priceComparisonAlgorithm": "TOTAL_PRICE",
               "onlyIncludeRecommendedOffers": false,
               "onlyIncludeRecommendationsWithOffersToBuy": true,
               "mixinOffersWithHigherFlexibility": true
             }
           }
        `}
    </pre>
    </ExpandablePanel>
    <br />
    <h3 {...{
      "id": "recommendation-response"
    }}>{`Recommendation response`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p>{`The `}<inlineCode parentName="p">{`Recommendation`}</inlineCode>{` object in the response from Offers contains the categories the recommendation is given fore, which matches the `}<inlineCode parentName="p">{`categorySpec`}</inlineCode>{` in the request.
In addition, it contains `}<inlineCode parentName="p">{`geographicalValidityCovered`}</inlineCode>{` - the geographical area the recommended offers are valid for.
This can be specific service journeys, validity between two stop places or zonal validity.
Which fields are present depends on the search endpoint used to get the recommendations and the products offered.
`}<inlineCode parentName="p">{`offersToBuy`}</inlineCode>{` lists the ids of the offers that needs to be reserved in the `}<a parentName="p" {...{
        "href": "/pages-sales-guides#reserve-offer"
      }}>{`Sales API`}</a>{` to cover the travellers in the recommendation.
`}<inlineCode parentName="p">{`selectableProductIds`}</inlineCode>{` might be populated and refer to selectable ids of optional products (found in the response entity under the field `}<inlineCode parentName="p">{`optionalProducts`}</inlineCode>{`)
that has to be reserved in combination with the offer to achieve the fare class of the recommendation, f.ex. premium where the optional product is a premium upgrade.
Selectable ids are parts of the request to the Sales API.`}</p>
    <ExpandablePanel title="Example Search Response with Recommendation" mdxType="ExpandablePanel">
    <pre>{`
    {
        "tripPatternId": "6df557eb-46e8-4d12-92e8-bea7bb1e41c5",
        "offers": [
            ...
        ],
        "recommendations": [
            {
                "geographicalValidityCovered": {
                    "serviceJourneys": [
                        "GOA:ServiceJourney:709_337-R"
                    ],
                    "pointToPointValidity": {
                        "fromPlace": "NSR:StopPlace:337",
                        "toPlace": "NSR:StopPlace:596"
                    }
                },
                "typeOfRecommendation": "FLEXIBLE",
                "durationType": "SINGLE_TRIP",
                "fareClass": "PREMIUM_CLASS",
                "facilitySet": "ANY_FACILITY_SET",
                "offersToBuy": [
                    {
                        "id": "7f000e63-a75d-48db-9d51-0341e3c9ebf4",
                        "numberToBuy": 1,
                        "selectableProductIds": [],
                        "possibleTravellerIds": [
                            [
                                "cfc293de-7ff0-400f-abaf-0e0033bf9691"
                            ]
                        ]
                    }
                ]
            },
            ...
        ],
        "optionalProducts": [
            ...
        ]
    }`}</pre>
    </ExpandablePanel>
    <h4 {...{
      "id": "travellermapping"
    }}>{`TravellerMapping`}</h4>
    <p>{`The `}<inlineCode parentName="p">{`possibleTravellerIds`}</inlineCode>{` field lists the ids of the travellers that are valid for each offer recommended. Note that each offer may have restrictions on how many travellers can be assigned to each offer,
described by its `}<inlineCode parentName="p">{`travellerMapping`}</inlineCode>{`. Each offer may be used by `}<inlineCode parentName="p">{`minNumberOfTravellers`}</inlineCode>{` and `}<inlineCode parentName="p">{`maxNumberOfTravellers`}</inlineCode>{` `}<em parentName="p">{`per`}</em>{` `}<inlineCode parentName="p">{`userType`}</inlineCode>{`. Note the difference between the last two examples.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`possibleTravellerIds`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`min/maxNumberOfTravellers`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`offerConfigurations`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`[{ "selectedTravellerIds": ["A"], ... }]`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A, B`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`[{ "selectedTravellerIds": ["A"], ...},{ "selectedTravellerIds": ["B"], ...}]`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A, B`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`[{ "selectedTravellerIds": ["A", "B"], ...}]`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A, B, C, D`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`[{ "selectedTravellerIds": ["A", "B"], ...}, { "selectedTravellerIds": ["C", "D"], ...}]`}</inlineCode><br /><em parentName="td">{`or`}</em><br /><inlineCode parentName="td">{`[{ "selectedTravellerIds": ["A", "C"], ...}, { "selectedTravellerIds": ["B", "D"], ...}]`}</inlineCode><br /><em parentName="td">{`or`}</em><br /><inlineCode parentName="td">{`[{ "selectedTravellerIds": ["A", "D"], ...}, { "selectedTravellerIds": ["B", "C"], ...}]`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A, B, C, D`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1 userType ADULT, travellerIds: `}{`[`}{`A, B`}{`]`}<br />{`1 userType CHILD, travellerIds: `}{`[`}{`C, D`}{`]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`[{ "selectedTravellerIds": ["A", "C"], ...}, { "selectedTravellerIds": ["B", "D"], ...}]`}</inlineCode><br /><em parentName="td">{`or`}</em><br /><inlineCode parentName="td">{`[{ "selectedTravellerIds": ["A", "D"], ...}, { "selectedTravellerIds": ["B", "C"], ...}]`}</inlineCode></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      